import { createSelector } from 'reselect';

import { ProductSummary, Weekday, ProductPricing } from 'models/product';
import { ReduxState } from 'ducks';

export interface ProductSummaryShape {
  id: string;
  name: string;
  description: string;
  imageURL: string;
  location: string;
  tags: string[];
  lowPrice: string;
  bestDiscountGross: string;
  bestDiscountPercent: number;
  operatingWeekdays: Weekday[];
  startTimezone: string;
  totalBooked: number;
  reviewRating: number;
  reviewCount: number;
  isFreeStartDateTime: boolean;
  checkinStubs: { key: string; text: string; options?: { key: string; text: string }[] }[];
  guestMyPageSettings: {
    shouldUseCustomSettings: boolean;
    allowCancellingReservation: boolean;
  };
  checkinExpirationDate: {
    relativeDate?: {
      type?: string;
      count?: number;
      timeLocal?: string;
    };
    displayType?: string;
  };
  shouldUseSimpleCheckin: boolean;
  shouldCountGuestsForCheckin: boolean;
  stubs: { key: string; text: string; options?: { key: string; text: string }[] }[];
  shouldUseETicket: boolean;
  termsOfServiceUrl: string;
  pricing: ProductPricing[];
  instantExpiration: boolean;
  noteForRedemptionModal: string;
  shouldShowOnlyForLoggedInCustomer?: boolean;
  isPackageSupplierProduct?: boolean;
  partnershipHubSupplierProductPageUrl?: string;
  originalLowPrice: string;
  haveFuturePriceRange?: boolean;
}

export const toProductSummaryShape = (productSummary: ProductSummary): ProductSummaryShape => {
  let imageURL = '';
  if (productSummary.media) {
    const firstImage = productSummary.media.find((item) => item.type === 'IMAGE');
    if (firstImage) {
      imageURL = firstImage.url;
    }
  }

  return {
    id: productSummary.id || '',
    name: productSummary.product_name || '',
    description: productSummary.description || '',
    imageURL,
    location: productSummary.area_name || '',
    tags: productSummary.product_tags || [],
    lowPrice: productSummary.lowest_price_gross || '',
    bestDiscountGross: productSummary.best_discount_gross || '',
    bestDiscountPercent: productSummary.best_discount_percent || 0,
    operatingWeekdays: productSummary.operating_days_of_week || [],
    startTimezone: productSummary.start_timezone || '',
    totalBooked: productSummary.total_booked || 0,
    reviewCount: productSummary.review_count ?? 0,
    reviewRating: productSummary.review_rating ?? 0,
    isFreeStartDateTime: productSummary.booking_widget_settings?.is_free_start_date_time ?? false,
    checkinStubs: productSummary.qr_checkin_settings?.stubs || [],
    guestMyPageSettings: {
      shouldUseCustomSettings:
        productSummary.guest_my_page_settings?.should_use_custom_settings ?? false,
      allowCancellingReservation:
        productSummary.guest_my_page_settings?.allow_cancelling_reservation ?? false,
    },
    checkinExpirationDate: {
      relativeDate: {
        type:
          productSummary.qr_checkin_settings?.expiration_date?.relative_date?.type || 'EXTERNAL',
        count: productSummary.qr_checkin_settings?.expiration_date?.relative_date?.count || 0,
        timeLocal:
          productSummary.qr_checkin_settings?.expiration_date?.relative_date?.time_local || '0:00',
      },
      displayType: productSummary.qr_checkin_settings?.expiration_date?.display_type || 'CLOCK',
    },
    shouldUseSimpleCheckin: productSummary.qr_checkin_settings?.should_use_simple_checkin ?? false,
    shouldCountGuestsForCheckin:
      productSummary.qr_checkin_settings?.should_count_guests_for_checkin ?? false,
    stubs: productSummary.qr_checkin_settings?.stubs || [],
    shouldUseETicket: productSummary.qr_checkin_settings?.should_use_e_ticket ?? false,
    termsOfServiceUrl: productSummary.qr_checkin_settings?.terms_of_service_url || '',
    instantExpiration:
      productSummary.qr_checkin_settings?.expiration_date?.instant_expiration || false,
    pricing: productSummary.pricing || [],
    noteForRedemptionModal: productSummary.qr_checkin_settings?.note_for_redemption_modal || '',
    shouldShowOnlyForLoggedInCustomer:
      productSummary.customer_ledger_settings?.should_show_only_for_logged_in_customer || false,
    isPackageSupplierProduct: productSummary.is_partnership_hub_supplier_product || false,
    partnershipHubSupplierProductPageUrl:
      productSummary.partnership_hub_supplier_product_page_url || '',
    originalLowPrice: productSummary.original_lowest_price_gross || '',
    haveFuturePriceRange: productSummary.have_future_price_range ?? false,
  };
};

export const selectProductSummaries = createSelector(
  (state: ReduxState) => state.server.productSummaries.all,
  (productSummaries) => productSummaries.map((p) => toProductSummaryShape(p))
);

export const selectPartnershipProductSummaries = createSelector(
  (state: ReduxState) => state.server.partnershipProductSummaries.all,
  (productSummaries) => productSummaries.map((p) => toProductSummaryShape(p))
);

export const selectPartnershipPackageProductSummaries = createSelector(
  (state: ReduxState) => state.server.partnershipProductSummaries.allPackages,
  (productSummaries) => productSummaries.map((p) => toProductSummaryShape(p))
);
